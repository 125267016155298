import React, { Component } from 'react';
import { GravisionRoute } from './routes';
import styled from 'styled-components';
class Gravision extends Component {
  render() {
    return (
      <BGDiv>
        <GravisionRoute />
      </BGDiv>
    );
  }
}
const BGDiv = styled.div`
  @media (max-width: 768px) {
    height: 100%;
  }
  /*display: flex;
  flex-direction: column;
  flex: 1; */
`;
export default Gravision;
