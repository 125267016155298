import React, { Component } from 'react';
import styled from 'styled-components';

class Organization extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: '조직도',
      organization: 'ORGANIZATION',
    };
  }

  render() {
    return (
      <Container>
        <OrganPageContainer>
          <OrganPage bg={require('../../aimg/organbgMofi.png')} />
        </OrganPageContainer>
        <Contents>
          <TextContainer>
            <TitleContent>{this.state.title}</TitleContent>
            <TitleSubContent>{this.state.organization}</TitleSubContent>
          </TextContainer>
          <ImageContiner>
            <OrganImg src={require('../../aimg/oraBg.png')} />
          </ImageContiner>
        </Contents>
      </Container>
    );
  }
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  align-items: center;
  /* height: 100%; */
  @media (max-width: 768px) {
    max-width: 768px;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
  }
`;
const Contents = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 107px;
  max-width: 1440px;
  width: 100%;
  @media (max-width: 768px) {
    max-width: 768px;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
  }
`;
const ImageContiner = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
`;
const OrganImg = styled.img`
  width: 100%;
  max-width: 1211px;
  height: auto;
  @media (max-width: 768px) {
    max-width: 324px;
  }
`;

const OrganPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  /* margin-bottom: 56px; */
`;
const OrganPage = styled.div`
  width: 100%;
  height: 405px;
  background: url(${require('../../aimg/organbgMofi.png')});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  @media (max-width: 768px) {
    height: 163px;
  }
`;
const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-bottom: 68px;
  margin-top: 56px;
  @media (max-width: 768px) {
    margin-top: 22px;
    margin-bottom: 42px;
  }
`;

const TitleContent = styled.div`
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: normal;
  font-size: 45px;
  line-height: 67px;
  letter-spacing: -0.05em;
  color: #292929;
  /* margin-bottom: 11px; */
  @media (max-width: 768px) {
    font-size: 17px;
    line-height: 25px;
  }
`;
const TitleSubContent = styled.div`
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: 350;
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  letter-spacing: -0.05em;
  color: #484848;
  opacity: 0.7;
  @media (max-width: 768px) {
    font-size: 11px;
    line-height: 16px;
  }
`;
export { Organization };
