import React, { Component } from 'react';
import styled from 'styled-components';
class Company extends Component {
  render() {
    return (
      <CompanyPageContainer>
        <CompanyPage bg={require('../../aimg/company.png')} alt='company.png' />
        <HelloPage>
          <HelloLeftDiv>
            <HelloText>
              안녕하세요.
              <br /> G-Vision 입니다.
            </HelloText>
          </HelloLeftDiv>
          <HelloRightDiv>
            <HelloExplainTop>
              함께 행복한 세상을 꿈꾸는 그래비젼은 <br />
              10대 여성쇼핑몰 언니가간다와
              <br />
              20대 고미스 쇼핑몰을 거치면서 <br />더 많은 사람들과 행복을 나누어
              왔으며,
              <br />
              작은 꿈이 더 큰 꿈을 꾸게함을 깨닫고 <br />
              그 꿈을 널리 펼치고자 스트릿브랜드
              <br />
              프랑켄모노까지 함께 하고 있습니다.
            </HelloExplainTop>
            <HelloExplainBottom>
              다년간 축적된 데이터와 기술력을 바탕으로 <br />
              국내외 쇼핑트렌드를 가늠할 수 있는 <br />
              대형 쇼핑몰과 검색엔진으로부터 <br />
              수집되는 방대한 양의 빅데이타를 기반으로 <br />
              그래비젼만의 노하우가 깃들여 있는 <br />
              분석 기술을 이용하여 더 빠르고 정확한 <br />
              유통 트렌드를 분석하고 예측하여 불확실성이 <br />
              지배하는 쇼핑몰 운영에 있어서의 <br />
              든든한 파트너가 되고 있습니다.
            </HelloExplainBottom>
          </HelloRightDiv>
        </HelloPage>
      </CompanyPageContainer>
    );
  }
}
// ===== CompanyPage ======
const CompanyPageContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  @media (max-width: 768px) {
    height: 100%;
  }
`;

const CompanyPage = styled.div`
  background: url(${(props) => props.bg}) no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 405px;
  @media (max-width: 768px) {
    height: 164px;
  }
`;

// ===== HelloPage ======
const HelloPage = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  max-width: 1440px;
  background-color: #ffffff;
  margin-bottom: 135px;
  @media (max-width: 768px) {
    max-width: 768px;
    /* min-width: 360px; */
    flex-direction: column;
    margin-bottom: 0px;
    padding-left: 48px;
    padding-right: 48px;
    /* height: 607px; */
  }
`;

const HelloLeftDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  margin-top: 152px;
  @media (max-width: 768px) {
    /* flex-direction: column; */
    margin-top: 58px;
    justify-content: flex-start;
    /* margin-left: 47px; 39px --> 47px 로 바꿈 2020-05-04(월) */
    margin-bottom: 57px;
  }
`;

const HelloText = styled.div`
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: bold;
  font-size: 67px;
  line-height: 70px;
  letter-spacing: -0.06em;
  color: #000000;
  @media (max-width: 768px) {
    font-size: 28px;
    line-height: 30px;
    letter-spacing: -0.05em;
  }
`;

const HelloRightDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 135px;
  @media (max-width: 768px) {
    margin-top: 0px;
    /* margin-left: 39px; */
  }
`;

const HelloExplainTop = styled.div`
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: 300;
  font-size: 34px;
  line-height: 57px;
  letter-spacing: -0.09em;
  color: #000000;
  margin-bottom: 106px;
  @media (max-width: 768px) {
    font-weight: 350;
    font-size: 15px;
    line-height: 23px;
    letter-spacing: -0.06em;
    margin-bottom: 40px;
  }
`;
const HelloExplainBottom = styled.div`
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: 300;
  font-size: 34px;
  line-height: 57px;
  letter-spacing: -0.09em;
  color: #000000;
  @media (max-width: 768px) {
    font-weight: 350;
    font-size: 15px;
    line-height: 23px;
    letter-spacing: -0.06em;
    margin-bottom: 56px; /*54 ===> 56 px 로 바꿈 2020-05-04(월)*/
  }
`;
export { Company };
