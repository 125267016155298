import React, { Component } from "react";
import styled from "styled-components";
class Business extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      a: false,
      pick: "",
      five: [
        {
          id: 1,
          bg: require("../../aimg/1.png"),
          header: `예측 AI`,
          title: `빅데이터 기반 AI마켓 \n플레이스 플렛폼을 \n구축하여 데이터의 수집부터 \n가공, 예측 AI(최적의 포트폴링) \n유통, 판매 및 피드백까지 \n실시간 이용자에게 제공`,
        },
        {
          id: 2,
          bg: require("../../aimg/2.png"),
          header: `데이터수집`,
          title: `자체적으로 직접 운영하는  \n인지도 높은 온라인 쇼핑몰과  \n스트리트 패션의 유행을 \n선도하는 메인 마켓 플레이스에  \n위치한 오프라인 매장의  \n직영에서 수집되는  \n신뢰도 높은 소비자 및 유통 데이터`,
        },
        {
          id: 3,
          bg: require("../../aimg/3.png"),
          header: ` 시장 분석`,
          title: `정확하고 빠른 트렌드 상품의 \n실제 소비자 반응도,  \n수집과 시장 흐름을 분석하고\n데이터화하여 온라인과\n오프라인에서의 상호 차이와\n연결점을 찾아내고 검증합니다.`,
        },
        {
          id: 4,
          bg: require("../../aimg/4.png"),
          header: `GBP 플랫폼`,
          title: `다양하고 빠르게 예측된  \nAI분석 정보를 바탕으로 보다  \n정확한 정보 기반으로 기획부터  \n판매까지 운영되는TOTALE-BIZ  \n시스템을 제공하여  \n안정된 비지니스 운영을 위한  \nGBP 플렛폼을 구현`,
        },
      ],
      six: {
        title: `GRAVISION`,
        icons: [
          {
            id: 1,
            title: "빅데이터 분석",
            content: `세밀히 분류하고 \n정확하게 분석 및 처리한 후\n 가공하는 비지니스`,
            img: require("../../aimg/six1.svg"),
          },
          {
            id: 2,
            title: "GBP솔루션",
            content: `빅데이터를 분석한 \n최상의 신뢰도`,
            img: require("../../aimg/six2.svg"),
          },
          {
            id: 3,
            title: "컨설팅",
            content: `신뢰도 높은 AI 예측 \n 데이터로 고객이 요구하는 \n니즈에 정확하게 \n부합하는 자료를 제공 `,
            img: require("../../aimg/six3.svg"),
          },
          {
            id: 4,
            title: `서비스`,
            content: `쇼핑몰 마케팅,\n  상품 수입 및\n 유통,판매 대행 등 \n다양한 서비스 제공`,
            img: require("../../aimg/six4.svg"),
          },
        ],
      },
      // color :[``,`#4048E2;`]
    };
  }

  render() {
    return (
      <Container>
        {this.businessOne()}
        {this.businessTwo()}
        {this.businessThree()}
        {this.businessFour()}
        {this.businessfive()}
        {this.businessSix()}
        {this.businessSeven()}
      </Container>
    );
  }
  businessOne = () => {
    return (
      <BgContainer bg={require("../../aimg/businessOnebg.png")} maxHeight="667">
        <Bginner>
          <OnePage>
            <div> BIG DATA BUSINESS</div>
            <div>From Gravision</div>
          </OnePage>
        </Bginner>
      </BgContainer>
    );
  };
  businessTwo = () => {
    return (
      <TwoContainer>
        <FixInner>
          <TextContainer>
            그래비젼은 소비자 패턴을 꾸준히 수집하여 축적된 노하우로 <br />
            가공하여 생산되는 데 기반으로 빠르게 선도해 <br />
            <TwoBlod>
              급변하는 시장경제의 흐름 속에서도 꾸준히 성장할 수 있는 시스템
              플랫폼
            </TwoBlod>
            을구축합니다
          </TextContainer>
          <MTextContainer>
            그래비젼은 소비자 패턴을 꾸준히 수집하여 축적된 노하우로 <br />
            가공하여 생산되는 데 기반으로 빠르게 선도해 <br />
            <TwoBlod>
              급변하는 시장경제의 흐름 속에서도 <br />
              꾸준히 성장할 수 있는 시스템 플랫폼
            </TwoBlod>
            을구축합니다
          </MTextContainer>
        </FixInner>
      </TwoContainer>
    );
  };
  businessThree = () => {
    return (
      <BgContainer bg={require("../../aimg/sub1.png")} maxHeight="668">
        <BgOp color="rgba(0,0,0,0.75)">
          <FixInner>
            <ContentTop>
              EBP를 통해 모두가 행복한 <br />
              <ContentBlue>
                사회적 공유 AI 쇼핑 플랫폼을 <br />
              </ContentBlue>
              만들어 가고 있습니다.
            </ContentTop>
            <SubLineDiv>
              <Subline />
            </SubLineDiv>
            <ContentBottom>
              행동 분석 시스템 Analysis of Behavior과 <br />
              그래비젼만의 기술력과 노하우로 <br />
              급변하는 쇼핑 시장에 최적화된 빅데이터 기반의
              <br /> GBP (G-Vision’s Big Data Shopping Platform)을
              <br /> 자유롭게 사용하세요.
            </ContentBottom>
          </FixInner>
        </BgOp>
      </BgContainer>
    );
  };

  businessFour = () => {
    return (
      <Container>
        <MiniContainer>
          <MiniImgDiv>
            <div>
              <PSGImg src={require("../../aimg/PGS.png")} alt="PGS.png" />
            </div>
            <div>
              <MPSGImg src={require("../../aimg/MPGS.png")} alt="MPGS.png" />
            </div>
          </MiniImgDiv>
          <NegativeContent>
            유통분야 Leading Company 의 필수조건인 <br />
            빅데이터 정보를 제공하며 <TextBlod>독창적인 아이디어 /</TextBlod>
            <br />
            <TextBlod> 디지털 기술 / 빅데이터 역량을 </TextBlod>활용합니다.
          </NegativeContent>
        </MiniContainer>
      </Container>
    );
  };

  // toggleHoverState = (x) => {
  //   this.setState({
  //     a: !this.state.a,
  //   });
  // };
  businessfive = () => {
    return (
      <WidthContainer>
        <BgFourContainer>
          {this.state.five.map((e, i) => {
            return (
              <BgImg
                bg={e.bg}
                key={i}
                onMouseOver={() => {
                  this.setState({
                    a: !this.state.a,
                    pick: i,
                  });
                }}
              >
                <HiddenDiv idx={i} pick={this.state.pick}>
                  <HiddenContents>
                    <FourBgContent
                      size="44"
                      lineHeight="60"
                      fontWeight="bold"
                      letterSpacing="-0.06"
                      margin="163px 0px 0px 0px"
                      Msize="22"
                      MmarginTop="49"
                    >
                      {e.header}
                    </FourBgContent>
                    <FourBgContent
                      size="26"
                      lineHeight="40"
                      fontWeight="350"
                      letterSpacing="-0.1"
                      margin="241px 0px 0px 0px"
                      Msize="12"
                      MlineHeight="18"
                      MmarginTop="75"
                    >
                      {e.title.split("\n").map(function (item, idx) {
                        return (
                          <span key={idx}>
                            {item}
                            <br />
                          </span>
                        );
                      })}
                    </FourBgContent>
                  </HiddenContents>
                </HiddenDiv>
              </BgImg>
            );
          })}
        </BgFourContainer>
      </WidthContainer>
    );
  };
  businessSix = () => {
    return (
      <MarginBottomSix>
        <BgContainer
          bg={require("../../aimg/companySixbg.png")}
          color="rgba(0,0,0,0.4)"
          maxHeight="211"
        >
          <BgOp color="rgba(0,0,0,0.4)">
            <Line>
              <VectorSix src={require("../../aimg/VectorSix.png")} />
              <EllipseSix src={require("../../aimg/EllipseSix.png")} />
            </Line>
            <Box>
              <TextTest>{this.state.six.title}</TextTest>
            </Box>
            <ContentContainer>
              <ContentBox bg={require("../../aimg/boxEffectSix.png")}>
                <IconContainer>
                  {this.state.six.icons.map((e, i) => {
                    return (
                      <Icon key={i}>
                        <BusinImg src={e.img} />
                        <div>
                          <div>{e.title}</div>
                          <div>
                            {e.content.split("\n").map(function (item, idx) {
                              return (
                                <span key={idx}>
                                  {item}
                                  <br />
                                </span>
                              );
                            })}
                          </div>
                        </div>
                      </Icon>
                    );
                  })}
                </IconContainer>
              </ContentBox>
            </ContentContainer>
          </BgOp>
        </BgContainer>
      </MarginBottomSix>
    );
  };
  businessSeven = () => {
    return (
      <AboutPage bg={require("../../aimg/about.png")}>
        <AboutOp>
          <AboutContntDiv>
            <AboutLeftDiv>
              <AboutTitle>
                <AboutBigTitle>
                  ABOUT <AboutBigTitleBlod>GRAVISION</AboutBigTitleBlod>{" "}
                </AboutBigTitle>
                <AboutSmTitle>
                  그래비젼은 누구나 자유롭게 이용할 수 있는 사회적 공유
                  플랫폼으로
                  <br /> 행복한 세상을 만들어가고 있습니다.
                </AboutSmTitle>
              </AboutTitle>
              <AboutBoxs>
                <MarketBox>
                  <AboutBoxList>
                    <AboutBoxTitle>시장분석</AboutBoxTitle>
                    <AboutBoxContent Mleft="46">
                      데이터를 얻는 능력, 데이터를 이해하고 처리하고 <br />
                      시각화 하는 능력
                    </AboutBoxContent>
                  </AboutBoxList>
                </MarketBox>
                <BigDataBox>
                  <AboutBoxList>
                    <AboutBoxTitle>빅데이터 활용</AboutBoxTitle>
                    <AboutBoxContent Mleft="23">
                      4차 산업혁명 시대 <br />
                      필수 조건은 빅데이터의 활용
                    </AboutBoxContent>
                  </AboutBoxList>
                </BigDataBox>
                <IdentityBox>
                  <AboutBoxList>
                    <AboutBoxTitle>Identity</AboutBoxTitle>
                    <AboutBoxContent Mleft="43">
                      그래비젼만의 빅데이터 분석 <br />
                      기술력 축적으로 시장 수요예측 프로세스 구축
                    </AboutBoxContent>
                  </AboutBoxList>
                </IdentityBox>
              </AboutBoxs>
            </AboutLeftDiv>
          </AboutContntDiv>
        </AboutOp>
      </AboutPage>
    );
  };
}
const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
`;
// const Con = styled.div`
//   display: flex;
//   flex-direction: column;
// `;
const BgContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  /* height: 100vh; */
  height: 810px;
  background: url(${(props) => props.bg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  align-items: center;
  background-color: ${(props) => props.color};
  @media (max-width: 768px) {
    max-height: ${(props) => props.maxHeight}px;
  }
`;
const BgOp = styled.div`
  /* background-color: rgba(0, 0, 0, 0.75); */
  background-color: ${(props) => props.color};
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 768px) {
    /* max-height: 211px; */
  }
`;
const Bginner = styled.div`
  width: 100%;
  max-width: 1440px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  @media (max-width: 768px) {
    max-width: 768px;
  }
`;
const OnePage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  margin-left: 110px;
  margin-right: 85px;
  margin-bottom: 135px;
  @media (max-width: 768px) {
    margin-left: 27px;
    margin-right: 0px;
    margin-bottom: 0px;
  }
  > div:first-child {
    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    line-height: 71px;
    letter-spacing: -0.03em;
    color: #ffffff;
    @media (max-width: 768px) {
      font-size: 23px;
      line-height: 34px;
      margin-bottom: 12px;
    }
  }
  > div:last-child {
    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: 300;
    font-size: 48px;
    line-height: 71px;
    letter-spacing: -0.02em;
    color: #ffffff;
    margin-top: -20px;
    @media (max-width: 768px) {
      font-size: 23px;
      line-height: 34px;
      /* margin-top: -7px; */
      margin-bottom: 54px;
    }
  }
`;

const TwoContainer = styled.div`
  /* width: 100%; */
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  align-items: center;
`;
const FixInner = styled.div`
  max-width: 1440px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${(props) => props.color};
  @media (max-width: 768px) {
    max-width: 768px;
  }
`;

const TextContainer = styled.div`
  width: 100%;
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: 300;
  font-size: 40px;
  line-height: 60px;
  text-align: center;
  letter-spacing: -0.13em;
  color: #000000;
  height: 100%;
  max-height: 180px;
  margin-top: 90px;
  margin-bottom: 90px;

  @media (max-width: 768px) {
    font-size: 15px;
    line-height: 25px;
    /* min-width: 307px;
    max-width: 307px; */
    margin-top: 32px;
    margin-bottom: 35px;
    display: none;
  }
`;
const MTextContainer = styled.div`
  width: 100%;
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: 300;
  font-size: 40px;
  line-height: 60px;
  text-align: center;
  letter-spacing: -0.13em;
  color: #000000;
  height: 100%;
  max-height: 180px;
  margin-top: 90px;
  margin-bottom: 90px;
  display: none;
  @media (max-width: 768px) {
    font-size: 15px;
    line-height: 25px;
    margin-top: 32px;
    margin-bottom: 35px;
    display: block;
  }
`;
const TwoBlod = styled.span`
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 60px;
  text-align: center;
  letter-spacing: -0.13em;
  color: #000000;
  @media (max-width: 768px) {
    font-size: 15px;
    line-height: 25px;
  }
`;
const MarginBottomSix = styled.div`
  margin-bottom: 322px;
  @media (max-width: 768px) {
    margin-bottom: 200px;
  }
`;

const WidthContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 812px;
  margin-bottom: 89px;
  align-items: center;

  @media (max-width: 768px) {
    max-height: 668px;
    /* height: 100%; */
    width: 100%;
    margin-bottom: 42px;
    /* margin-left: auto; */
    /* margin-right: auto; */
  }
`;
const BgFourContainer = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 1440px;
  width: 100%;
  height: 100%;
  @media (max-width: 768px) {
    max-width: 334px;
    max-height: 668px;
    height: 100%;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
  }
`;
const BgImg = styled.div`
  position: relative;
  /* width: 361px; */
  width: 25%;
  height: 100%;
  max-height: 812px;
  background-image: url(${(props) => props.bg});
  @media (max-width: 768px) {
    /* width: 188px; */
    width: 50%;
    height: 100%;
    max-height: 334px;
    background-size: cover;
    /* display: block; */
    white-space: normal;
  }
`;

const HiddenDiv = styled.div`
  display: ${(props) => {
    return props.idx === props.pick ? "flex" : "none";
  }};
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: rgba(4, 15, 217, 0.5);
`;
// const BgBlueContainer = styled.div``;

const HiddenContents = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px;
  @media (max-width: 768px) {
    margin: 10px;
  }
`;
const FourBgContent = styled.div`
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: ${(props) => props.fontWeight};
  font-size: ${(props) => props.size}px;
  line-height: ${(props) => props.lineHeight}px;
  /* or 154% */
  letter-spacing: ${(props) => props.letterSpacing}em;
  color: #ffffff;
  margin: ${(props) => props.margin};
  @media (max-width: 768px) {
    margin: 0px;
    margin-top: ${(props) => props.MmarginTop}px;
    font-size: ${(props) => props.Msize}px;
    line-height: ${(props) => props.MlineHeight}px;
  }
`;

// const Contents = styled.div`
//   font-family: Noto Sans KR;
//   font-style: normal;
//   max-width: 810px;
//   text-align: center;
//   width: 100%;
//   margin-top: 177px;
// `;
const ContentTop = styled.div`
  max-width: 810px;
  text-align: center;
  width: 100%;
  margin-top: 177px;
  font-family: Noto Sans KR;
  font-style: normal;
  /* font-weight: 500; */
  font-size: 60px;
  line-height: 74px;
  letter-spacing: -0.02em;
  color: #ffffff;

  @media (max-width: 768px) {
    font-size: 28px;
    line-height: 35px;
    letter-spacing: -0.09em;
    margin-top: 174px;
  }
`;
const ContentBlue = styled.div`
  max-width: 810px;
  text-align: center;
  width: 100%;
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: bold;
  font-size: 60px;
  line-height: 74px;
  letter-spacing: -0.02em;
  color: #4048e2;
  @media (max-width: 768px) {
    font-size: 28px;
    line-height: 35px;
    letter-spacing: -0.09em;
  }
`;
const SubLineDiv = styled.div`
  /* margin: auto; */
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-top: 27px;
  @media (max-width: 768px) {
    margin-top: 32px;
  }
`;
const Subline = styled.div`
  height: 39px;
  border: 1px solid #ffffff;
  @media (max-width: 768px) {
    height: 30px;
  }
`;
const ContentBottom = styled.div`
  max-width: 810px;
  text-align: center;
  width: 100%;
  margin-top: 32px;
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: 300;
  font-size: 22px;
  line-height: 33px;
  letter-spacing: -0.04em;
  color: #ffffff;
  @media (max-width: 768px) {
    font-weight: 350;
    font-size: 15px;
    line-height: 23px;
    letter-spacing: -0.07em;
  }
`;

// const Content = styled.div`
//   font-family: Noto Sans KR;
//   font-style: normal;
//   font-weight: ${(props) => props.fontWeight};
//   font-size: ${(props) => props.size}px;
//   line-height: ${(props) => props.lineHeight}px;
//   margin: ${(props) => props.margin};

//   letter-spacing: -0.02em;

//   color: #ffffff;
// `;

// const MaginOne = styled.div`
//   margin-left: 110px;
//   margin-top: ${(props) => props.marginTop}px;
// `;

const MiniContainer = styled.div`
  display: flex;
  min-height: 454px;
  align-items: center;
  justify-content: center;
  height: 640px;
  background: #ffffff;
  @media (max-width: 768px) {
    flex-direction: column;
    min-height: 0px;
    height: 518px;
  }
`;
const MiniImgDiv = styled.div`
  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;
const PSGImg = styled.img`
  @media (max-width: 768px) {
    display: none;
  }
`;
const MPSGImg = styled.img`
  display: none;
  @media (max-width: 768px) {
    display: block;
    margin-bottom: 20px;
  }
`;
const NegativeContent = styled.div`
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: 100;
  font-size: 29px;
  line-height: 43px;
  max-width: 590px;
  letter-spacing: -0.02em;
  margin-left: 88px;
  color: rgba(0, 0, 0, 0.9);
  @media (max-width: 768px) {
    font-weight: 300;
    font-size: 15px;
    line-height: 22px;
    text-align: center;
    letter-spacing: -0.06em;
    margin-left: 0px;
    max-width: 768px;
  }
`;
const TextBlod = styled.span`
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: bold;
  font-size: 29px;
  line-height: 43px;
  letter-spacing: -0.02em;
  color: #000000;
  @media (max-width: 768px) {
    font-size: 15px;
    line-height: 22px;
    text-align: center;
    letter-spacing: -0.06em;
  }
`;

const Line = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 768px) {
    align-items: end;
  }
`;
const VectorSix = styled.img`
  height: 232px;
  @media (max-width: 768px) {
    height: 55px;
  }

  /* position: absolute; */
`;
const EllipseSix = styled.img`
  /* position: absolute; */
  margin-top: 224px;
  margin-left: -6px;
  @media (max-width: 768px) {
    margin-top: 52px;
    margin-left: -2px;
    max-width: 5px;
  }
`;

// const Border = styled.div`
//   width: 340px;
//   height: 110px;
//   background: #ffffff;
//   opacity: 0.35;
// `;
// const Opacity = styled.div`
//   width: 340px;
//   height: 110px;
//   border: 2px solid #ffffff;
//   box-sizing: border-box;
// `;
const Box = styled.div`
  display: flex;
  align-items: center;
  margin-top: 57px;
  @media (max-width: 768px) {
    margin-top: 15px;
  }
`;
const TextTest = styled.div`
  border: 1px solid white;
  background: rgba(255, 255, 255, 0.5);
  width: 393px;
  height: 85px;
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: 900;
  font-size: 43px;
  line-height: 35px;
  text-align: center;
  letter-spacing: 0.05em;
  color: #ffffff;
  padding-top: 25px;
  @media (max-width: 768px) {
    width: 150px;
    height: 34px;
    font-size: 14px;
    /* line-height: 60px; */
    padding-top: 0px;
  }
`;
const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  max-width: 1440px;
  width: 100%;
  flex-direction: column;
  @media (max-width: 768px) {
    max-width: 375px;
    max-height: 373px;
    margin-top: 105px;
    /* background-color: red; */
  }
`;
const ContentBox = styled.div`
  max-width: 1040px;
  width: 100%;
  height: 504px;
  background-image: url(${(props) => props.bg});
  background-repeat: no-repeat;
  background-position: center;
  margin-top: 241px;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    margin-top: 0px;
    max-width: 768px;
    max-height: 373px;
    height: 100%;
  }
`;
const IconContainer = styled.div`
  margin-top: 81px;
  display: flex;
  flex-direction: row;
  max-width: 1040px;
  width: 100%;
  justify-content: center;
  align-items: start;
  @media (max-width: 768px) {
    max-width: 768px;
    height: 100%;
    width: 100%;
    margin-top: 20px;
    flex-wrap: wrap;
  }
`;

const Icon = styled.div`
  /* height: 217px; */
  align-items: center;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  margin: 0px auto 0px auto;
  color: #3e3a39;
  @media (max-width: 768px) {
    font-size: 8px;
    line-height: 12px;
    width: 50%;
  }
  > div {
    /* margin-top: 10px; */
    margin-top: 15px;
    text-align: center;
    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    /* line-height: 60px; */
    /* identical to box height, or 333% */
    letter-spacing: -0.03em;
    color: #3e3a39;
    @media (max-width: 768px) {
      font-weight: bold;
      font-size: 11px;
      line-height: 16px;
      margin-top: 0px;
    }
    > div:last-child {
      margin-top: 18px;
      font-family: Noto Sans KR;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 19px;
      /* or 136% */
      text-align: center;
      letter-spacing: -0.05em;
      color: #666261;
      @media (max-width: 768px) {
        font-style: normal;
        font-weight: normal;
        font-size: 11px;
        line-height: 16px;
        text-align: center;
        letter-spacing: -0.1em;
      }
    }
  }
`;

const BusinImg = styled.img`
  width: 120px;
  @media (max-width: 768px) {
    width: 38px;
  }
`;
// ========== AboutPage ===============

const AboutPage = styled.div`
  /* position: relative; */
  display: flex;
  width: 100%;
  height: 808px;
  background-image: url(${(props) => props.bg});
  /* background: url('../../aimg/about.png') no-repeat; */
  background-size: cover;
  background-position: center;
  @media (max-width: 768px) {
    min-height: 462px;
    height: 100%;
    margin-top: 120px;
  }
`;
const AboutOp = styled.div`
  /* position: absolute; */
  width: 100%;
  height: 100%;
  /* left: 0%;
  right: 0%;
  top: 0px; */
  background: rgba(0, 0, 0, 0.7);
  /* opacity: 0.7; */
  @media (max-width: 768px) {
    min-height: 462px;
    height: 100%;
  }
`;
const AboutContntDiv = styled.div`
  max-width: 1440px;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  @media (max-width: 768px) {
    max-width: 768px;
    /* min-width: 375px; */
    flex-direction: column;
    align-items: center;
    /* background-color: blue; */
  }
`;

const AboutLeftDiv = styled.div`
  margin-left: 130px;
  margin-top: 205px;
  /* width: 100%; */
  @media (max-width: 768px) {
    margin-left: 0px;
    margin-top: 94px;
    /* min-width: 375px; */
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    /* width: 100%;  이거를 끄니깐 화면이 안깨지뮤*/
    /* border: 1px solid red; */
  }
`;

const AboutTitle = styled.div``;

const AboutBigTitle = styled.div`
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: 350;
  font-size: 60px;
  line-height: 89px;
  letter-spacing: -0.02em;
  color: #ffffff;
  @media (max-width: 768px) {
    font-size: 21px;
    line-height: 31px;
  }
`;

const AboutBigTitleBlod = styled.span`
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: bold;
  font-size: 60px;
  line-height: 89px;
  letter-spacing: -0.02em;
  color: #ffffff;
  @media (max-width: 768px) {
    font-size: 21px;
    line-height: 31px;
  }
`;
const AboutSmTitle = styled.div`
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: 300;
  font-size: 33px;
  line-height: 50px;
  letter-spacing: -0.01em;
  color: #ffffff;
  @media (max-width: 768px) {
    font-size: 10px;
    line-height: 15px;
    letter-spacing: -0.05em;
  }
`;

const AboutBoxs = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 72px;
  @media (max-width: 768px) {
    margin-top: 30px;
    flex-direction: column;
  }
`;
const AboutBoxList = styled.div`
  margin: 20px;
  /* margin: 30px 27px 28px 24px; */
  /* padding: 30px 27px 28px 24px; */
  /* width: 190px; */
  /* height: 60px; */
  @media (max-width: 768px) {
    margin: 0px;
    /* width: 95px;
    height: 71px; */
    display: flex;
    margin-left: 15px;
    margin-top: 13px;
  }
`;
const AboutBoxTitle = styled.div`
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: bold;
  font-size: 27px;
  line-height: 40px;
  letter-spacing: 0.02em;
  color: #ffffff;
  margin-bottom: 21px;
  @media (max-width: 768px) {
    font-size: 9px;
    line-height: 13px;
    margin-bottom: 5px;
  }
`;
const AboutBoxContent = styled.div`
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: 350;
  font-size: 21px;
  line-height: 31px;
  letter-spacing: -0.1em;
  color: #ffffff;
  opacity: 0.9;
  @media (max-width: 768px) {
    font-weight: 350;
    font-size: 7px;
    line-height: 15px;
    margin-left: ${(props) => props.Mleft}px;
  }
`;

const MarketBox = styled.div`
  border: 2px solid #ffffff;
  box-sizing: border-box;
  width: 343px;
  height: 232px;
  margin-right: 69px;
  background: rgba(255, 255, 255, 0.15);
  @media (max-width: 768px) {
    /* width: 100%; */
    /* height: 100%; */
    max-width: 309px;
    max-height: 57px;
    margin-bottom: 11px;
    margin-right: 0px;
  }
`;
const BigDataBox = styled.div`
  border: 2px solid #ffffff;
  box-sizing: border-box;
  /* position: absolute; */
  width: 343px;
  height: 232px;
  margin-right: 69px;
  background: rgba(255, 255, 255, 0.15);
  @media (max-width: 768px) {
    /* width: 100%; */
    /* height: 100%; */
    max-width: 309px;
    max-height: 57px;
    margin-bottom: 11px;
    margin-right: 0px;
  }
`;
const IdentityBox = styled.div`
  border: 2px solid #ffffff;
  box-sizing: border-box;
  /* position: absolute; */
  width: 343px;
  height: 232px;
  background: rgba(255, 255, 255, 0.15);
  @media (max-width: 768px) {
    max-width: 309px;
    max-height: 57px;
    /* height: 100%; */
    /* width: 100%; */
    margin-right: 0px;
  }
`;

// const AboutRightDiv = styled.div`
//   margin-right: auto;
//   margin-left: auto;
//   margin-top: 186px;
//   @media (max-width: 768px) {
//     margin-top: 0px;
//     display: flex;
//     flex-direction: row;
//     margin-top: 20px;
//   }
// `;
// const AboutCustomer = styled.div`
//   background: rgba(64, 72, 226, 0.9);
//   width: 332px;
//   height: 213px;
//   display: flex;
//   margin-bottom: 37px;
//   @media (max-width: 768px) {
//     width: 147px;
//     height: 87px;
//     margin-right: 17px;
//   }
// `;
// const CustomerBox = styled.div`
//   margin-left: 46px;
//   margin-top: 34px;
//   @media (max-width: 768px) {
//     margin-left: 18px;
//     margin-top: 15px;
//   }
// `;
// const CustomerTitle = styled.div`
//   font-family: Noto Sans KR;
//   font-style: normal;
//   font-weight: normal;
//   font-size: 20px;
//   line-height: 30px;
//   letter-spacing: -0.01em;
//   color: #ffffff;
//   @media (max-width: 768px) {
//     font-size: 8px;
//     line-height: 12px;
//   }
// `;
// const CustomerNumber = styled.div`
//   font-family: Noto Sans KR;
//   font-style: normal;
//   font-weight: bold;
//   font-size: 36px;
//   line-height: 53px;
//   letter-spacing: 0.02em;
//   color: #ffffff;
//   margin-bottom: 24px;
//   @media (max-width: 768px) {
//     font-size: 14px;
//     line-height: 21px;
//     margin-bottom: 5px;
//   }
// `;
// const CustomerFaxandMail = styled.div`
//   font-family: Noto Sans KR;
//   font-style: normal;
//   font-weight: 350;
//   font-size: 16px;
//   /* line-height: 10px;*/
//   /* or 62% */
//   letter-spacing: 0.08em;
//   color: #ffffff;
//   opacity: 0.75;
//   @media (max-width: 768px) {
//     font-size: 6px;
//     line-height: 10px;
//   }
// `;

// const AboutWay = styled.div`
//   background: rgba(47, 39, 37, 0.9);
//   width: 332px;
//   height: 213px;
//   display: flex;
//   @media (max-width: 768px) {
//     width: 147px;
//     height: 87px;
//   }
// `;
// const WayBox = styled.div`
//   margin-left: 46px;
//   margin-top: 34px;
//   @media (max-width: 768px) {
//     margin-left: 18px;
//     margin-top: 15px;
//   }
// `;

// const WayTitle = styled.div`
//   font-family: Noto Sans KR;
//   font-style: normal;
//   font-weight: normal;
//   font-size: 21px;
//   line-height: 31px;
//   /* identical to box height */

//   letter-spacing: -0.01em;

//   color: #ffffff;
//   margin-bottom: 48px;
//   @media (max-width: 768px) {
//     font-size: 8px;
//     line-height: 12px;
//     margin-bottom: 20px;
//   }
// `;
// const WayAddress = styled.div`
//   font-family: Noto Sans KR;
//   font-style: normal;
//   font-weight: 350;
//   font-size: 16px;
//   line-height: 24px;
//   letter-spacing: 0.03em;
//   color: #ffffff;
//   opacity: 0.75;
//   @media (max-width: 768px) {
//     font-size: 7px;
//     line-height: 15px;
//     letter-spacing: 0.08em;
//   }
// `;
export { Business };
