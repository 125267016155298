import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import {
  MainingPage,
  Company,
  Business,
  Organization,
  NoticeingPage,
  ScrollToTop,
} from "../screen";
import {
  NavigationBarPage,
  FooterContainer,
  // CarouselContainer,
} from "../containers";

import styled from "styled-components";
class GravisionRoute extends Component {
  // constructor(props, context) {
  //   super(props, context);
  // }
  render() {
    return (
      <Router>
        <Nav>
          <NavigationBarPage />
        </Nav>
        <ScrollToTop>
          <Switch>
            <Route exact path="/" component={MainingPage} />
            <Route path="/company" component={Company} />
            <Route path="/business" component={Business} />
            <Route path="/organization" component={Organization} />
            <Route path="/notice" component={NoticeingPage} />
          </Switch>
        </ScrollToTop>
        <FooterContainer />
        {/* <CarouselContainer /> */}
      </Router>
    );
  }
}

const Nav = styled.div`
  position: absolute;
  width: 100%;
  z-index: 999;
`;
export { GravisionRoute };
