import React, { Component } from "react";
import styled from "styled-components";
// import { Link } from 'react-router-dom';
import { withRouter } from "react-router-dom";
// import { connect } from 'react-redux';
// import { menuList } from '../../modules';
class NavigationBarContainer extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      pick: "",
      menuVisible: false,
      // renderMobileMenu: false,
    };
  }
  renderMobileClose = () => {
    this.setState({
      menuVisible: false,
    });
  };
  renderMobileMenu = () => {
    return (
      <TempMenu>
        <MmenuImg
          src={require("../../aimg/mmenu.png")}
          alt="mmenu.png"
          onClick={() => {
            console.log("ㅎㅎㅎㅎㅎ야야야야야");
            this.setState({
              menuVisible: true,
            });
          }}
        />
        {this.state.menuVisible === true ? this.rederModal() : null}
      </TempMenu>
    );
  };
  rederModal = () => {
    return (
      <React.Fragment>
        <ModelOverlay />
        <MobileHalf>
          <ModalMenuContainer>
            <MImgDiv>
              <ModalInGraLogoImg
                src={require("../../aimg/logo.png")}
                alt="mModalLogo.png"
                onClick={() => {
                  this.renderMobileClose();
                  this.props.history.push("/");
                }}
              />
              <MXmenuImg
                src={require("../../aimg/mXmenu.png")}
                alt="mXmenu.png"
                onClick={() => {
                  console.log("모야모야");
                  this.setState({
                    menuVisible: "",
                  });
                }}
              />
              {this.state.menuVisible === "" ? (
                <MXmenuImg style={{ display: "none" }} />
              ) : null}
            </MImgDiv>
            <ModalLine />
            <ModalMenuDiv>
              <MobileMenuText
                pick={this.state.pick}
                onClick={() => {
                  this.setState({
                    pick: "COMPANY",
                  });
                  this.renderMobileClose();
                  this.props.history.push("/company");
                }}
              >
                COMPANY
              </MobileMenuText>

              <MobileMenuText
                pick={this.state.pick}
                onClick={() => {
                  this.setState({
                    pick: "BUSINESS",
                  });
                  this.renderMobileClose();
                  this.props.history.push("/business");
                }}
              >
                BUSINESS
              </MobileMenuText>
              <MobileMenuText
                pick={this.state.pick}
                onClick={() => {
                  this.setState({
                    pick: "NOTICE",
                  });
                  this.renderMobileClose();
                  this.props.history.push("/notice");
                }}
              >
                NOTICE
              </MobileMenuText>
              <MobileMenuText
                pick={this.state.pick}
                onClick={() => {
                  this.setState({
                    pick: "ORGANIZATION",
                  });
                  this.renderMobileClose();
                  this.props.history.push("/organization");
                }}
              >
                ORGANIZATION
              </MobileMenuText>
            </ModalMenuDiv>
          </ModalMenuContainer>
        </MobileHalf>
      </React.Fragment>
    );
  };
  render() {
    return (
      <NavBarContainer>
        <NavContent>
          <MobileMenuDiv>{this.renderMobileMenu()}</MobileMenuDiv>
          <NavBarLeftContainer>
            {/* <Link to='/'> */}
            <GraLogoImg
              src={require("../../aimg/logo.png")}
              alt="logo"
              onClick={() => {
                this.setState({
                  pick: "",
                });
                this.props.history.push("/");
              }}
            />
            {/* </Link> */}
          </NavBarLeftContainer>
          <NavBarRightContainer>
            <MenuDiv>
              <MenuText
                pick={this.state.pick}
                onClick={() => {
                  this.setState({
                    pick: "COMPANY",
                  });
                  this.props.history.push("/company");
                }}
              >
                COMPANY
              </MenuText>
              <MenuText
                pick={this.state.pick}
                onClick={() => {
                  this.setState({
                    pick: "BUSINESS",
                  });
                  this.props.history.push("/business");
                }}
              >
                BUSINESS
              </MenuText>
              <MenuText
                pick={this.state.pick}
                onClick={() => {
                  this.setState({
                    pick: "NOTICE",
                  });
                  this.props.history.push("/notice");
                }}
              >
                NOTICE
              </MenuText>
              <MenuText
                pick={this.state.pick}
                onClick={() => {
                  this.setState({
                    pick: "ORGANIZATION",
                  });
                  this.props.history.push("/organization");
                }}
              >
                ORGANIZATION
              </MenuText>
            </MenuDiv>
          </NavBarRightContainer>
        </NavContent>
      </NavBarContainer>
    );
  }
}

const NavBarContainer = styled.div`
  display: flex;
  height: auto;
  width: 100%;
  z-index: 999;
  justify-content: center;
  /* background-color: red; */
  @media (max-width: 768px) {
  }
`;
// const MobileContainer = styled.div`
//   display: flex;
//   height: auto;
//   width: 100%;
//   z-index: 999;
//   justify-content: center;
//   display: none;
//   @media (max-width: 768px) {
//     background-color: red;
//     width: 100%;
//     display: flex;
//     justify-content: flex-start;
//   }
// `;
const NavContent = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 1440px;
  height: auto;
  margin-top: 42px;
  margin-left: 24px;
  margin-right: 24px;
  @media (max-width: 768px) {
    min-width: 375px;
    max-width: 768px;
    margin-top: 21px;
    margin-left: 0px;
    margin-right: 0px;
  }
`;
// const MobileContent = styled.div`
//   @media (max-width: 768px) {
//     margin-left: 26px;
//   }
// `;
const MmenuImg = styled.img`
  @media (max-width: 768px) {
  }
`;

const ModelOverlay = styled.div`
  position: fixed;
  display: flex;
  z-index: 50;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: transparent;
  /* height: auto; */
  /* background-color: rgba(0, 0, 0, 0.5); */
  height: 668px;
`;
const MobileHalf = styled.div`
  display: flex;
  position: fixed;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  width: 50%;
  /* height: 668px; */
  height: 100%;
  min-width: 180px;
  max-width: 768px;
  top: 0px;
  left: 0;
  right: 0;
  z-index: 100;
  right: 0;
  background-color: rgba(0, 0, 0, 0.85);
`;
// const MenuModel = styled.div`
//   /*  */
//   @media (max-width: 768px) {
//     width: 100%;
//     background-color: blue;
//   }
// `;
// const MenuDetail = styled.div`
//   @media (max-width: 768px) {
//     width: 100%;
//     background-color: yellow;
//   }
// `;
const NavBarLeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 110px;
  /* width: 50%; */
  @media (max-width: 768px) {
    margin-left: 9px;
    display: block;
  }
`;
const NavBarRightContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-right: 85px;
  @media (max-width: 768px) {
    margin-right: 0px;
  }
`;

const GraLogoImg = styled.img`
  width: 100%;
  max-width: 144px;
  height: auto;
  max-height: 26px;
  cursor: pointer;
  @media (max-width: 768px) {
    max-width: 100px;
    cursor: pointer;
  }
`;
const ModalInGraLogoImg = styled.img`
  max-width: 92px;
  margin-bottom: 10px;
  margin-right: 39px;
`;
const MXmenuImg = styled.img`
  width: 100%;
  max-height: 14px;
  max-width: 14px;
`;
const ModalLine = styled.div`
  width: 146px;
  height: 0px;
  border: 0.8px solid rgba(255, 255, 255, 0.5);
  margin-bottom: 15px;
`;
const MImgDiv = styled.div`
  display: flex;
  flex-direction: row;
`;
const MobileMenuDiv = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: row;
    /* width: 100%; */
    justify-content: flex-start;
    text-decoration: none;
    margin-left: 26px;
  }
`;
const TempMenu = styled.div``;
const MenuDiv = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
  text-decoration: none;
  @media (max-width: 768px) {
    display: none;
  }
`;
const MenuText = styled.div`
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: ${(props) => (props.children === props.pick ? "800" : "500")};
  font-size: 16px;
  line-height: 22px;
  color: ${(props) =>
    props.children === props.pick ? "#ffffff" : "rgba(255, 255, 255, 0.5)"};
  margin-right: ${(props) =>
    props.children === "ORGANIZATION" ? "0px" : "51px"};
  cursor: pointer;
  @media (max-width: 768px) {
    display: none;
  }
`;
const MobileMenuText = styled.div`
  @media (max-width: 768px) {
    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: ${(props) => (props.children === props.pick ? "800" : "500")};
    font-size: 16px;
    line-height: 22px;
    color: ${(props) =>
      props.children === props.pick ? "#ffffff" : "rgba(255, 255, 255, 0.5)"};
    margin-right: ${(props) =>
      props.children === "ORGANIZATION" ? "0px" : "51px"};
    margin-bottom: 18px;
    cursor: pointer;
  }
`;
const ModalMenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 768px) {
    margin-top: 33px;
    margin-left: 25px;
  }
`;

const ModalMenuDiv = styled.div``;
export const NavigationBarPage = withRouter(NavigationBarContainer);
