import React, { Component } from "react";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import { CarouselContainer } from "../../containers/carouselContainer";

class Main extends Component {
  render() {
    return (
      <MainPageContainer>
        <CarouselOver>
          <CarouselContainer />
        </CarouselOver>
        <LogoPage>
          <LogoImg src={require("../../aimg/Frame.png")} alt="Frame.png" />
        </LogoPage>
        <SubPage bg={require("../../aimg/sub1.png")} alt="sub1.png">
          <Subop>
            <SubContentDiv>
              <SubHeader>
                EBP를 통해 모두가 행복한 <br />
                <SubHeaderBlue>사회적 공유 AI 쇼핑 플랫폼을</SubHeaderBlue>
                만들어 가고 있습니다.
              </SubHeader>
              <SubBotton>
                {console.log("프롭스", this.props.history.push)}
                <SubLabel
                  onClick={() => {
                    this.props.history.push("/business");
                  }}
                >
                  + more
                </SubLabel>
              </SubBotton>
              <SubLineDiv>
                <Subline />
              </SubLineDiv>
              <SubFooter>
                행동 분석 시스템 Analysis of Behavior과 <br />
                그래비젼만의 기술력과 노하우로 <br />
                급변하는 쇼핑 시장에 최적화된 빅데이터 기반의
                <br />
                GBP(G-Vision’s Big Data Shopping Platform)을 <br />
                자유롭게 사용하세요.
              </SubFooter>
            </SubContentDiv>
          </Subop>
        </SubPage>
        <Container>
          <MiniContainer>
            <MiniImgDiv>
              <div>
                <PSGImg src={require("../../aimg/PGS.png")} alt="PGS.png" />
              </div>
              <div>
                <MPSGImg src={require("../../aimg/MPGS.png")} alt="PGS.png" />
              </div>
            </MiniImgDiv>
            <NegativeContent>
              유통분야 Leading Company 의 필수조건인 <br />
              빅데이터 정보를 제공하며 <TextBlod>
                독창적인 아이디어 /
              </TextBlod>{" "}
              <br />
              <TextBlod> 디지털 기술 / 빅데이터 역량을 </TextBlod>활용합니다.
            </NegativeContent>
          </MiniContainer>
        </Container>
        <TwoImgPage>
          <div>
            <OneImg src={require("../../aimg/left.png")} alt="left.png" />
          </div>
          <div>
            <TwoImg src={require("../../aimg/right.png")} alt="right.png" />
          </div>
        </TwoImgPage>
        <WeArePage>
          <WeContentDiv>
            <WeHeaderContents>
              <WeHeader>
                <WeBigTitle fontWeight="350">
                  WE ARE <WeBigTitle fontWeight="900">GRAVISION</WeBigTitle>
                </WeBigTitle>
                <WeSmallTitle>
                  All information on Marketplace via Big Data
                </WeSmallTitle>
              </WeHeader>
            </WeHeaderContents>
            <WeIcon>
              <div>
                <WeMembers
                  src={require("../../aimg/members.png")}
                  alt="members.png"
                />

                <WeStores
                  src={require("../../aimg/stores.png")}
                  alt="stores.png"
                />

                <WeOverseas
                  src={require("../../aimg/overseas.png")}
                  alt="overseas.png"
                />

                <WeWorld src={require("../../aimg/no1.png")} alt="no1.png" />
              </div>
            </WeIcon>
          </WeContentDiv>
        </WeArePage>
        <AboutPage bg={require("../../aimg/about.png")}>
          <AboutOp>
            <AboutContntDiv>
              <AboutLeftDiv>
                <AboutTitle>
                  <AboutBigTitle>
                    ABOUT <AboutBigTitleBlod>GRAVISION</AboutBigTitleBlod>{" "}
                  </AboutBigTitle>
                  <AboutSmTitle>
                    그래비젼은 누구나 자유롭게 이용할 수 있는 사회적 공유
                    플랫폼으로
                    <br /> 행복한 세상을 만들어가고 있습니다.
                  </AboutSmTitle>
                </AboutTitle>
                <AboutBoxs>
                  <MarketBox>
                    <AboutBoxList>
                      <AboutBoxTitle>시장분석</AboutBoxTitle>
                      <AboutBoxContent Mleft="46">
                        데이터를 얻는 능력, 데이터를 이해하고 처리하고 <br />{" "}
                        시각화 하는 능력
                      </AboutBoxContent>
                    </AboutBoxList>
                  </MarketBox>
                  <BigDataBox>
                    <AboutBoxList>
                      <AboutBoxTitle>빅데이터 활용</AboutBoxTitle>
                      <AboutBoxContent Mleft="23">
                        4차 산업혁명 시대 <br />
                        필수 조건은 빅데이터의 활용
                      </AboutBoxContent>
                    </AboutBoxList>
                  </BigDataBox>
                  <IdentityBox>
                    <AboutBoxList>
                      <AboutBoxTitle>Identity</AboutBoxTitle>
                      <AboutBoxContent Mleft="43">
                        그래비젼만의 빅데이터 분석 <br />
                        기술력 축적으로 시장 수요예측 프로세스 구축
                      </AboutBoxContent>
                    </AboutBoxList>
                  </IdentityBox>
                </AboutBoxs>
              </AboutLeftDiv>
            </AboutContntDiv>
          </AboutOp>
        </AboutPage>
      </MainPageContainer>
    );
  }
}
/* 이미지 MainPage 에  100% 로 잡아줄것 ----*/
const CarouselOver = styled.div`
  display: flex;
  /* width: 1440px; */
  width: 100%;
  height: auto;
  /* overflow: hidden; */
`;

const MainPageContainer = styled.div`
  /* overflow-x: hidden; */
  flex-direction: column;
  width: 100%;
  flex: 1;
  display: flex;
  background: #ffffff;
`;

// ========== logoPage ===============

const LogoPage = styled.div`
  background: #ffffff;
  width: 100%;
  /* max-width: 1440px; */
  height: 808px;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    display: none;
  }
`;
const LogoImg = styled.img`
  position: absolute;
  width: 528px;
  height: 96px;
  display: flex;
`;
// ========== SubPage ===============
const SubPage = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 808px;
  background: url(${(props) => props.bg}) no-repeat;
  background-size: cover;
  background-position: center;
  @media (max-width: 768px) {
    height: 668px;
  }
`;
const Subop = styled.div`
  display: flex;
  position: absolute;
  height: 808px;
  left: 0.21%;
  right: -0.21%;

  background: #000000;
  /* opacity: 0.75; */
  background-color: rgba(0, 0, 0, 0.75);
  border: 1px solid #000000;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  @media (max-width: 768px) {
    height: 668px;
  }
`;
const SubContentDiv = styled.div`
  max-width: 1440px;
  margin-right: auto;
  margin-left: auto;
`;
const SubHeader = styled.div`
  margin-top: 139px;
  margin-bottom: 35px;
  bottom: 61.32%;
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: 500;
  font-size: 60px;
  line-height: 74px;
  text-align: center;
  letter-spacing: -0.05em; /* 2020-0424 자간 –5%,*/
  color: #ffffff;
  @media (max-width: 768px) {
    /* font-weight: 500; */
    font-size: 28px;
    line-height: 35px;
    letter-spacing: -0.09em;
    margin-bottom: 52px;
    margin-top: 174px;
  }
`;
const SubHeaderBlue = styled.div`
  color: #4048e2;
  font-weight: bold;
  @media (max-width: 768px) {
    font-size: 28px;
    letter-spacing: -0.09em;
  }
`;
const SubBotton = styled.button`
  width: 168px;
  height: 44px;
  background: #4048e2;
  border-radius: 22px;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: 768px) {
    width: 132px;
    height: 40px;
  }
`;
const SubLabel = styled.div`
  margin-left: auto;
  margin-right: auto;
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 40px;
  text-align: center;
  letter-spacing: 0.05em;
  font-feature-settings: "tnum" on, "lnum" on;
  color: #ffffff;
`;
// const Subline = styled.div`
//   position: absolute;
//   left: 50.42%;
//   right: 49.58%;
//   top: 59.76%;
//   bottom: 33.45%;
//   border: 1px solid #ffffff;
// `;

const SubLineDiv = styled.div`
  /* margin: auto; */
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-top: 42px;
  margin-bottom: 27px;
  @media (max-width: 768px) {
    margin-top: 31px;
    margin-bottom: 29px;
  }
`;
const Subline = styled.div`
  height: 39px;
  border: 1px solid #ffffff;
`;
const SubFooter = styled.div`
  /* position: absolute;
  left: 19.55%;
  right: 20.53%;
  top: 71.25%;
  bottom: 0%; */
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: 300;
  font-size: 22px;
  line-height: 33px;
  text-align: center;
  letter-spacing: -0.04em;
  color: #ffffff;
  @media (max-width: 768px) {
    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: 350;
    font-size: 15px;
    line-height: 23px;

    text-align: center;
    letter-spacing: -0.07em;
    color: rgba(255, 255, 255, 0.9);
  }
`;

// ========== ParadigmPage ===============
const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
`;
const MiniContainer = styled.div`
  display: flex;
  min-height: 454px;
  align-items: center;
  justify-content: center;
  height: 640px;
  background: #ffffff;
  @media (max-width: 768px) {
    flex-direction: column;
    min-height: 0px;
    height: 518px;
  }
`;
const MiniImgDiv = styled.div`
  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;
const PSGImg = styled.img`
  max-width: 609px;
  width: 100%;
  @media (max-width: 768px) {
    display: none;
  }
`;
const MPSGImg = styled.img`
  display: none;
  @media (max-width: 768px) {
    display: block;
    margin-bottom: 20px;
  }
`;
const NegativeContent = styled.div`
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: 100;
  font-size: 29px;
  line-height: 43px;
  max-width: 590px;
  letter-spacing: -0.02em;
  margin-left: 88px;
  color: rgba(0, 0, 0, 0.9);
  @media (max-width: 768px) {
    font-weight: 300;
    font-size: 15px;
    line-height: 22px;
    text-align: center;
    letter-spacing: -0.06em;
    margin-left: 0px;
    max-width: 768px;
  }
`;
const TextBlod = styled.span`
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: bold;
  font-size: 29px;
  line-height: 43px;
  letter-spacing: -0.02em;
  color: #000000;
  @media (max-width: 768px) {
    font-size: 15px;
    line-height: 22px;
    text-align: center;
    letter-spacing: -0.06em;
  }
`;
// const ParadigmPage = styled.div`
//   width: 100%;
//   height: 640px;
//   display: flex;
//   background: #ffffff;
// `;

// const ParContnetDiv = styled.div`
//   max-width: 1440px;
//   display: flex;
//   flex-direction: row;
//   margin-left: auto;
//   margin-right: auto;
// `;

// const ParImgDiv = styled.div`
//   margin-top: auto;
//   margin-bottom: auto;
//   margin-right: 88px;
// `;

// const ParPGSImg = styled.img``;

// const ParTextDiv = styled.div`
//   margin-top: auto;
//   margin-bottom: auto;
// `;

// const ParText = styled.div`
//   left: calc(50% - 509px / 2 + 348.5px);
//   top: 268px;
//   bottom: 243px;

//   font-family: Noto Sans KR;
//   font-style: normal;
//   font-weight: 300;
//   font-size: 29px;
//   line-height: 43px;
//   letter-spacing: -0.06em;
//   color: rgba(0, 0, 0, 0.9);
//   opacity: 0.9;
// `;

// ========== TwoImgPage ===============

const TwoImgPage = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  /* max-height: 454px; */
  max-width: 1440px;
  flex-direction: row;
  justify-content: center;

  margin-right: auto;
  margin-left: auto;
  > div {
    /* max-width: 720px; */
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  @media (max-width: 768px) {
    max-width: 768px;
    max-height: 119px;
    /* width: auto; */
    > div {
      max-width: 180px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
`;

const OneImg = styled.img`
  width: 100%;
  max-width: 720px;
  @media (max-width: 768px) {
    width: 100%;
    max-width: 188px;
    /* height: 119px; */
  }
`;
const TwoImg = styled.img`
  width: 100%;
  max-width: 720px;
  @media (max-width: 768px) {
    width: 100%;
    max-width: 188px;
    /* height: 119px; */
  }
`;

// ========== WeArePage ===============
const WeArePage = styled.div`
  height: 808px;
  width: 100%;
  display: flex;
  background: #ffffff;
  @media (max-width: 768px) {
    max-height: 394px;
    height: 100%;
  }
`;
const WeContentDiv = styled.div`
  max-width: 1440px;
  margin-right: auto;
  margin-left: auto;
  @media (max-width: 768px) {
    max-width: 768px;
    max-height: 394px;
  }
`;

const WeHeaderContents = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const WeHeader = styled.div`
  text-align: center;
  margin-top: 158px;
  @media (max-width: 768px) {
    margin-top: 52px;
  }
`;
const WeBigTitle = styled.span`
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: ${(props) => props.fontWeight};
  font-size: 60px;
  line-height: 87px;
  /* identical to box height */
  letter-spacing: -0.02em;
  color: #000000;
  @media (max-width: 768px) {
    /* font-weight: 350; */
    font-size: 22px;
    line-height: 33px;
  }
`;
// const WeBigTitleSpan = styled.span
const WeSmallTitle = styled.div`
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: 350;
  font-size: 30px;
  line-height: 44px;

  color: #000000;

  opacity: 0.65;
  @media (max-width: 768px) {
    font-size: 11px;
    line-height: 16px;
  }
`;

const WeIcon = styled.div`
  margin-top: 95px;
  > div {
    width: 100%;
    text-align: center;
  }
  @media (max-width: 768px) {
    margin-top: 35px;
    margin-bottom: 60px;
    width: 212px;
  }
`;
const WeMembers = styled.img`
  width: 27%;
  @media (max-width: 768px) {
    width: 106px;
    margin-right: -15px;
    margin-bottom: -16px;
  }
`;
const WeStores = styled.img`
  width: 27%;
  margin-left: -38px;
  @media (max-width: 768px) {
    margin-left: 0px;
    width: 106px;
    margin-bottom: -16px;
  }
`;
const WeOverseas = styled.img`
  width: 27%;
  margin-left: -38px;
  @media (max-width: 768px) {
    margin-left: 0px;
    width: 106px;
    margin-right: -15px;
  }
`;
const WeWorld = styled.img`
  width: 27%;
  margin-left: -38px;
  @media (max-width: 768px) {
    margin-left: 0px;
    width: 106px;
  }
`;

// ========== AboutPage ===============

const AboutPage = styled.div`
  /* position: relative; */
  display: flex;
  width: 100%;
  height: 808px;
  background: url(${(props) => props.bg}) no-repeat;
  background-size: cover;
  @media (max-width: 768px) {
    height: 462px;
    /* height: 100%; */
    /* flex-direction: column; */
    /* align-items: center; */
  }
`;
const AboutOp = styled.div`
  /* position: absolute; */
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  @media (max-width: 768px) {
    min-height: 462px;
    height: 100%;
  }
`;
const AboutContntDiv = styled.div`
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding: 205px 5% 0px;
  display: flex;
  flex-direction: row;
  /* border: 1px solid red; */
  @media (max-width: 768px) {
    max-width: 768px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 100px 130px 0 130px;
    text-align: center;
    /* background-color: blue; */
  }
`;

const AboutLeftDiv = styled.div`
  /* margin-left: 130px; */
  /* max-width: 1440px; */
  width: 100%;
  /* margin-top: 205px; */
  /* display: flex;
  flex-direction: column; */
  /* align-items: center; */
  /* border: 1px solid red; */
  justify-content: center;
  @media (max-width: 768px) {
    /* max-width: 768px; */
    /* margin-top: 94px; */
    margin-left: 0px;
    /* display: flex; */
    /* border: 1px solid red; */
  }
`;

const AboutTitle = styled.div``;
const AboutBigTitle = styled.div`
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: 350;
  font-size: 60px;
  line-height: 89px;
  /* identical to box height */
  letter-spacing: -0.02em;
  color: #ffffff;
  @media (max-width: 768px) {
    font-size: 21px;
    line-height: 31px;
  }
`;
const AboutBigTitleBlod = styled.span`
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: bold;
  font-size: 60px;
  line-height: 89px;
  letter-spacing: -0.02em;
  color: #ffffff;
  @media (max-width: 768px) {
    font-size: 21px;
    line-height: 31px;
  }
`;

const AboutSmTitle = styled.div`
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: 300;
  font-size: 33px;
  line-height: 50px;
  letter-spacing: -0.01em;
  color: #ffffff;
  @media (max-width: 768px) {
    letter-spacing: -0.05em;
    font-size: 10px;
    line-height: 15px;
  }
`;
const AboutBoxs = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 72px;
  @media (max-width: 768px) {
    margin-top: 30px;
    flex-direction: column;
    align-items: center;
  }
`;

const MarketBox = styled.div`
  border: 2px solid #ffffff;
  box-sizing: border-box;
  /* width: 343px; */
  width: 29%;
  height: 232px;
  /* width: 100%; */
  /* max-width: 343px;
  max-height: 232px; */
  /* margin-right: 21px; */
  /* margin-right: 69px; */
  background: rgba(255, 255, 255, 0.15);
  @media (max-width: 768px) {
    width: 100%;
    max-width: 309px;
    max-height: 57px;
    margin-bottom: 11px;
    margin-right: 0px;
  }
`;

// const MarinInner = styled.div`
//   @media (max-width: 768px) {
//     max-width: 95px;
//     max-height: 71px;
//     margin-right: 14px;
//     margin: 10px;
//   }
// `;
// const MarketTitle = styled.div`
//   font-family: Noto Sans KR;
//   font-style: normal;
//   font-weight: bold;
//   font-size: 19px;
//   line-height: 28px;
//   letter-spacing: 0.02em;
//   color: #ffffff;
//   margin-top: 30px;
//   margin-left: 24px;
//   @media (max-width: 768px) {
//     font-weight: bold;
//     font-size: 8px;
//     line-height: 12px;
//     margin-top: 0px;
//     margin-left: 0px;
//   }
// `;
// const MarketContent = styled.div`
//   font-family: Noto Sans KR;
//   font-style: normal;
//   font-weight: 300;
//   font-size: 14px;
//   line-height: 21px;
//   letter-spacing: -0.1em;

//   color: #ffffff;
//   opacity: 0.9;
//   margin-top: 21px;
//   margin-left: 24px;
//   @media (max-width: 768px) {
//     font-weight: 350;
//     font-size: 6px;
//     line-height: 9px;
//     margin-top: 6px;
//     margin-left: 0px;
//   }
// `;

const BigDataBox = styled.div`
  border: 2px solid #ffffff;
  box-sizing: border-box;
  /* position: absolute; */
  /* width: 343px; */
  width: 29%;
  height: 232px;
  /* max-width: 343px;
  max-height: 232px; */
  /* margin-right: 21px; */
  /* margin-right: 69px; */
  background: rgba(255, 255, 255, 0.15);
  @media (max-width: 768px) {
    width: 100%;
    max-width: 309px;
    max-height: 57px;
    margin-bottom: 11px;
    margin-right: 0px;
  }
`;
// const BigDataTitle = styled.div`
//   font-family: Noto Sans KR;
//   font-style: normal;
//   font-weight: bold;
//   font-size: 19px;
//   line-height: 28px;
//   letter-spacing: 0.02em;
//   color: #ffffff;
//   margin-top: 30px;
//   margin-left: 24px;
//   @media (max-width: 768px) {
//     font-weight: bold;
//     font-size: 8px;
//     line-height: 12px;
//     margin-top: 0px;
//     margin-left: 0px;
//   }
// `;
// const BigDataContent = styled.div`
//   font-family: Noto Sans KR;
//   font-style: normal;
//   font-weight: 300;
//   font-size: 14px;
//   line-height: 21px;
//   letter-spacing: -0.1em;
//   color: #ffffff;
//   opacity: 0.9;
//   margin-top: 21px;
//   margin-left: 24px;
//   @media (max-width: 768px) {
//     font-weight: 350;
//     font-size: 6px;
//     line-height: 9px;
//     margin-top: 6px;
//     margin-left: 0px;
//   }
// `;

const IdentityBox = styled.div`
  border: 2px solid #ffffff;
  box-sizing: border-box;
  /* width: 343px; */
  width: 29%;
  height: 232px;
  /* max-width: 343px;
  max-height: 232px; */
  /* margin-right: 69px; */
  background: rgba(255, 255, 255, 0.15);
  @media (max-width: 768px) {
    width: 100%;
    max-width: 309px;
    max-height: 57px;
    margin-right: 0px;
  }
`;
// const IdentityTitle = styled.div`
//   font-family: Noto Sans KR;
//   font-style: normal;
//   font-weight: bold;
//   font-size: 19px;
//   line-height: 28px;
//   letter-spacing: 0.02em;

//   color: #ffffff;
//   margin-top: 30px;
//   margin-left: 24px;
//   @media (max-width: 768px) {
//     font-weight: bold;
//     font-size: 8px;
//     line-height: 12px;
//     margin-top: 0px;
//     margin-left: 0px;
//   }
// `;
// const IdentityContent = styled.div`
//   font-family: Noto Sans KR;
//   font-style: normal;
//   font-weight: 300;
//   font-size: 14px;
//   line-height: 21px;
//   letter-spacing: -0.1em;

//   color: #ffffff;
//   margin-top: 21px;

//   opacity: 0.9;
//   margin-left: 24px;

//   @media (max-width: 768px) {
//     font-weight: 350;
//     font-size: 6px;
//     line-height: 9px;
//     margin-top: 6px;
//     margin-left: 0px;
//   }
// `;

// const AboutRightDiv = styled.div`
//   margin-right: auto;
//   margin-left: auto;
//   margin-top: 186px;
//   @media (max-width: 768px) {
//     margin-top: 0px;
//     display: flex;
//     flex-direction: row;
//     margin-top: 20px;
//     margin-right: 0px;
//     margin-left: 33px;
//   }
// `;

// const AboutCustomer = styled.div`
//   background: rgba(64, 72, 226, 0.9);
//   width: 332px;
//   height: 213px;
//   display: flex;
//   margin-bottom: 37px;
//   @media (max-width: 768px) {
//     width: 147px;
//     height: 87px;
//     margin-right: 17px;
//   }
// `;
// const CustomerBox = styled.div`
//   margin-left: 46px;
//   margin-top: 34px;
//   @media (max-width: 768px) {
//     margin-left: 18px;
//     margin-top: 15px;
//   }
// `;
// const CustomerTitle = styled.div`
//   font-family: Noto Sans KR;
//   font-style: normal;
//   font-weight: normal;
//   font-size: 20px;
//   line-height: 30px;
//   /* identical to box height */
//   letter-spacing: -0.01em;
//   color: #ffffff;
//   @media (max-width: 768px) {
//     font-size: 8px;
//     line-height: 12px;
//   }
// `;
// const CustomerNumber = styled.div`
//   font-family: Noto Sans KR;
//   font-style: normal;
//   font-weight: bold;
//   font-size: 36px;
//   line-height: 53px;
//   letter-spacing: 0.02em;
//   color: #ffffff;
//   margin-bottom: 24px;
//   @media (max-width: 768px) {
//     font-size: 14px;
//     line-height: 21px;
//     margin-bottom: 5px;
//   }
// `;
// const CustomerFaxandMail = styled.div`
//   font-family: Noto Sans KR;
//   font-style: normal;
//   font-weight: 350;
//   font-size: 16px;
//   letter-spacing: 0.08em;
//   color: #ffffff;
//   opacity: 0.75;
//   @media (max-width: 768px) {
//     font-size: 6px;
//     line-height: 10px;
//   }
// `;

// const AboutWay = styled.div`
//   background: rgba(47, 39, 37, 0.9);
//   width: 332px;
//   height: 213px;
//   display: flex;
//   @media (max-width: 768px) {
//     width: 147px;
//     height: 87px;
//   }
// `;
// const WayBox = styled.div`
//   margin-left: 46px;
//   margin-top: 34px;
//   @media (max-width: 768px) {
//     margin-left: 18px;
//     margin-top: 15px;
//   }
// `;

// const WayTitle = styled.div`
//   font-family: Noto Sans KR;
//   font-style: normal;
//   font-weight: normal;
//   font-size: 21px;
//   line-height: 31px;
//   /* identical to box height */
//   letter-spacing: -0.01em;
//   color: #ffffff;
//   margin-bottom: 48px;
//   @media (max-width: 768px) {
//     font-size: 8px;
//     line-height: 12px;
//     margin-bottom: 20px;
//   }
// `;
// const WayAddress = styled.div`
//   font-family: Noto Sans KR;
//   font-style: normal;
//   font-weight: 350;
//   font-size: 16px;
//   line-height: 24px;
//   letter-spacing: 0.03em;
//   color: #ffffff;
//   opacity: 0.75;
//   @media (max-width: 768px) {
//     font-size: 7px;
//     line-height: 15px;
//     letter-spacing: 0.08em;
//   }
// `;
// about page

const AboutBoxList = styled.div`
  margin: 6%;
  /* margin: 30px 27px 28px 24px; */
  /* padding: 30px 27px 28px 24px; */
  /* width: 190px; */
  /* height: 60px; */
  @media (max-width: 768px) {
    margin: 0px;
    /* width: 95px;
    height: 71px; */
    display: flex;
    margin-left: 15px;
    margin-top: 13px;
  }
`;
const AboutBoxTitle = styled.div`
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: bold;
  font-size: 27px;
  line-height: 40px;
  letter-spacing: 0.02em;
  color: #ffffff;
  margin-bottom: 21px;
  @media (max-width: 768px) {
    font-size: 9px;
    line-height: 13px;
    margin-bottom: 5px;
  }
`;
const AboutBoxContent = styled.div`
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: 350;
  font-size: 21px;
  line-height: 1.5em;
  letter-spacing: -0.1em;
  color: #ffffff;
  opacity: 0.9;
  @media (max-width: 768px) {
    font-weight: 350;
    font-size: 7px;
    line-height: 15px;
    text-align: left;
    margin-left: ${(props) => props.Mleft}px;
  }
`;
export const MainingPage = withRouter(Main);
