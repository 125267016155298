import React, { Component } from "react";
import styled from "styled-components";
class FooterContainer extends Component {
  render() {
    return (
      <FooterPage>
        <FootContentDiv>
          <FooterText>
            대표 : Back Seung Hoon <br />
            사업자등록번호 : 746-87-01727 <br />
            주소 : (06162) 서울특별시 강남구 선릉로94길 14 9층
            <br />
            <br />
            Copyright © g-vision.co.kr All rights reserved.
          </FooterText>
        </FootContentDiv>
      </FooterPage>
    );
  }
}

const FooterPage = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  background-color: #040017;
  justify-content: center;
  @media (max-width: 768px) {
    /* width: 100%; */
    /* background-color: blue; */
  }
`;

const FootContentDiv = styled.div`
  max-width: 1440px;
  margin-top: 38px;
  margin-bottom: 35px;
  @media (max-width: 768px) {
    min-width: 375px;
    max-width: 768px;
    margin-top: 13px;
    margin-bottom: 13px;
  }
`;

const FooterText = styled.div`
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 26px;
  text-align: center;
  letter-spacing: -0.05em;
  color: #ffffff;
  opacity: 0.7;
  @media (max-width: 768px) {
    font-size: 5.5px;
    line-height: 13px;
    text-align: center;
  }
`;

export { FooterContainer };
