import React, { Component } from "react";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
// import { BoardList } from '../../components';
class Notice extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      two: [`공지사항`],
      temp: [
        {
          no: 1,
          title: "안녕하세요",
          actor: "정민경",
          select: 1,
          date: "2020-04-28",
        },
        {
          no: 2,
          title: "안녕하세요",
          actor: "정민경",
          select: 1,
          date: "2020-04-28",
        },
        {
          no: 3,
          title: "안녕하세요",
          actor: "정민경",
          select: 1,
          date: "2020-04-28",
        },
        {
          no: 4,
          title: "안녕하세요",
          actor: "정민경",
          select: 1,
          date: "2020-04-28",
        },
        {
          no: 5,
          title: "안녕하세요",
          actor: "정민경",
          select: 1,
          date: "2020-04-28",
        },
        {
          no: 6,
          title: "안녕하세요",
          actor: "정민경",
          select: 1,
          date: "2020-04-28",
        },
      ],
      cate: [
        {
          name: `전체`,
          data: [
            {
              no: 1,
              title: "안녕하세요",
              actor: "정민경",
              select: 1,
              date: "2020-04-28",
            },
            {
              no: 2,
              title: "안녕하세요",
              actor: "정민경",
              select: 1,
              date: "2020-04-28",
            },
            {
              no: 3,
              title: "안녕하세요",
              actor: "정민경",
              select: 1,
              date: "2020-04-28",
            },
            {
              no: 4,
              title: "안녕하세요",
              actor: "정민경",
              select: 1,
              date: "2020-04-28",
            },
            {
              no: 5,
              title: "안녕하세요",
              actor: "정민경",
              select: 1,
              date: "2020-04-28",
            },
            {
              no: 6,
              title: "안녕하세요",
              actor: "정민경",
              select: 1,
              date: "2020-04-28",
            },
          ],
        },
        {
          name: `공지사항`,
          data: [
            {
              no: 1,
              title: "공지공지",
              actor: "정민경",
              select: 1,
              date: "2020-04-28",
            },
            {
              no: 2,
              title: "공지공지",
              actor: "정민경",
              select: 1,
              date: "2020-04-28",
            },
            {
              no: 3,
              title: "공지공지",
              actor: "정민경",
              select: 1,
              date: "2020-04-28",
            },
            {
              no: 4,
              title: "공지공지",
              actor: "정민경",
              select: 1,
              date: "2020-04-28",
            },
            {
              no: 5,
              title: "공지공지",
              actor: "정민경",
              select: 1,
              date: "2020-04-28",
            },
            {
              no: 6,
              title: "공지공지",
              actor: "정민경",
              select: 1,
              date: "2020-04-28",
            },
          ],
        },
        {
          name: `Q&A`,
          data: [
            {
              no: 1,
              title: "큐앤에이",
              actor: "정민경",
              select: 1,
              date: "2020-04-28",
            },
            {
              no: 2,
              title: "큐앤에이",
              actor: "정민경",
              select: 1,
              date: "2020-04-28",
            },
            {
              no: 3,
              title: "큐앤에이",
              actor: "정민경",
              select: 1,
              date: "2020-04-28",
            },
            {
              no: 4,
              title: "큐앤에이",
              actor: "정민경",
              select: 1,
              date: "2020-04-28",
            },
            {
              no: 5,
              title: "큐앤에이",
              actor: "정민경",
              select: 1,
              date: "2020-04-28",
            },
            {
              no: 6,
              title: "큐앤에이",
              actor: "정민경",
              select: 1,
              date: "2020-04-28",
            },
          ],
        },

        {
          name: `기타문의`,
          data: [
            {
              no: 1,
              title: "키타문의",
              actor: "정민경",
              select: 1,
              date: "2020-04-28",
            },
            {
              no: 2,
              title: "키타문의",
              actor: "정민경",
              select: 1,
              date: "2020-04-28",
            },
            {
              no: 3,
              title: "키타문의",
              actor: "정민경",
              select: 1,
              date: "2020-04-28",
            },
            {
              no: 4,
              title: "키타문의",
              actor: "정민경",
              select: 1,
              date: "2020-04-28",
            },
            {
              no: 5,
              title: "키타문의",
              actor: "정민경",
              select: 1,
              date: "2020-04-28",
            },
            {
              no: 6,
              title: "키타문의",
              actor: "정민경",
              select: 1,
              date: "2020-04-28",
            },
          ],
        },
      ],

      pick: "전체",
      number: "1",
    };
  }

  render() {
    return (
      <Container>
        {this.noticeOne()}
        <Noti>{this.noticeTwo()}</Noti>
        <Noti>{this.noticeCate()}</Noti>
        <Noti>{this.noticeFour()}</Noti>
        <Noti>{this.noticeFive()}</Noti>

        <SearchNoti>{this.noticeSix()}</SearchNoti>
      </Container>
    );
  }
  noticeOne = () => {
    return (
      <NoticePageContainer>
        <NoticePage bg={require("../../aimg/noticebg.png")} />
      </NoticePageContainer>
    );
  };

  noticeTwo = () => {
    const [a, b] = this.state.two;
    return (
      <TextContainer>
        <Contents
          weight="normal"
          lineHeight="65"
          size="45"
          color=" #292929"
          mbottom="0"
          Msize="15"
          MlineHeight="22"
        >
          {a}
        </Contents>
        <Contents
          weight="300"
          lineHeight="26"
          size="20"
          color="rgba(72, 72, 72, 0.7)"
          mbottom="57"
          Msize="7"
          Mweight="350"
          Mmbottom="0"
        >
          {b}
        </Contents>
      </TextContainer>
    );
  };

  noticeCate = () => {
    return (
      <React.Fragment>
        <MenuContainer style={{ borderCollapse: "collapse" }}>
          {this.state.cate.map((item, idx) => {
            console.log("item ", item);
            return (
              <Menu
                onClick={() => {
                  this.setState({
                    pick: item.name,
                    temp: item.data,
                  });
                }}
                key={idx}
              >
                <MunuLabel bold={this.state.pick} color={this.state.pick}>
                  {item.name}
                </MunuLabel>
              </Menu>
            );
          })}
        </MenuContainer>
        {/* <BoardList list={this.state.temp} /> */}
      </React.Fragment>
    );
  };

  noticeFour = () => {
    return (
      <Container
        margin="0px 89px 0px 110px"
        height="700"
        Mmargin="0px 0px 0px 0px"
        Mheight="450"
      >
        <Line></Line>
        <NoticeDiv>
          <NoticeLabel RightMargin="427" MRightMargin="70">
            번호
          </NoticeLabel>
          <NoticeLabel RightMargin="427" MRightMargin="70">
            제목
          </NoticeLabel>
          <NoticeLabel RightMargin="85" MRightMargin="30">
            글쓴이
          </NoticeLabel>
          <NoticeLabel RightMargin="85" MRightMargin="30">
            조회
          </NoticeLabel>
          <NoticeLabel>날짜</NoticeLabel>
        </NoticeDiv>
        {/* <BoardList list={this.state.temp} /> */}
      </Container>
    );
  };

  noticeFive = () => {
    return (
      <>
        <PagingContainer>
          <PagingDiv>
            <Paging>
              <PagingButton
                src={require("../../aimg/noticeButton.png")}
                alt="noticeButton"
              />
            </Paging>
            <Paging>
              <PagingLabel
                bold={this.state.number}
                color={this.state.number}
                onClick={() => {
                  this.setState({
                    number: "1",
                  });
                }}
              >
                1
              </PagingLabel>
            </Paging>
            <Paging>
              <PagingLabel
                bold={this.state.number}
                color={this.state.number}
                onClick={() => {
                  this.setState({
                    number: "2",
                  });
                }}
              >
                2
              </PagingLabel>
            </Paging>
            <Paging>
              <PagingLabel
                bold={this.state.number}
                color={this.state.number}
                onClick={() => {
                  this.setState({
                    number: "3",
                  });
                }}
              >
                3
              </PagingLabel>
            </Paging>
            <Paging>
              <PagingLabel
                bold={this.state.number}
                color={this.state.number}
                onClick={() => {
                  this.setState({
                    number: "4",
                  });
                }}
              >
                4
              </PagingLabel>
            </Paging>
            <Paging>
              <PagingLabel
                bold={this.state.number}
                color={this.state.number}
                onClick={() => {
                  this.setState({
                    number: "5",
                  });
                }}
              >
                5
              </PagingLabel>
            </Paging>
            <Paging>
              <PagingButton
                src={require("../../aimg/noticeButton2.png")}
                alt="noticeButton2"
              />
            </Paging>
          </PagingDiv>
        </PagingContainer>
      </>
    );
  };
  noticeSix = () => {
    return (
      <SearchDiv>
        <SearchTop>
          <SearchInput></SearchInput>
          <SearchGlass src={require("../../aimg/Glass.png")} alt="Glass" />
        </SearchTop>
        <SearchLine></SearchLine>
      </SearchDiv>
    );
  };
}
const Container = styled.div`
  height: 100%;
  /* flex: 1; */
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: ${(props) => props.margin};
  height: ${(props) => props.height}px;
  @media (max-width: 768px) {
    margin: ${(props) => props.Mmargin};
    height: ${(props) => props.Mheight}px;
    /* min-width: 321px; */
  }
`;
// const BoardMini = styled.div``;

const Noti = styled.div`
  display: flex;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    max-width: 768px;
  }
`;
const SearchNoti = styled.div`
  display: flex;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: 768px) {
    max-width: 768px;
  }
`;
const NoticePageContainer = styled.div`
  width: 100%;
  /* flex: 1; */
`;
const NoticePage = styled.div`
  background: url(${(props) => props.bg}) no-repeat;
  background-size: cover;
  background-position: center;
  height: 405px;
  @media (max-width: 768px) {
    height: 163px;
  }
`;

const TextContainer = styled.div`
  margin-top: 54px;
  @media (max-width: 768px) {
    margin-top: 22px;
  }
`;
const Contents = styled.div`
  display: flex;
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: ${(props) => props.weight};
  font-size: ${(props) => props.size}px;
  line-height: ${(props) => props.lineHeight}px;
  letter-spacing: -0.05em;
  color: ${(props) => props.color};
  margin-bottom: ${(props) => props.mbottom}px;
  justify-content: center;
  @media (max-width: 768px) {
    font-size: ${(props) => props.Msize}px;
    line-height: ${(props) => props.MlineHeight}px;
    font-weight: ${(props) => props.Mweight};
    margin-bottom: ${(props) => props.Mmbottom}px;
  }
`;
const MenuContainer = styled.div`
  display: flex;
  justify-content: center;
  min-width: 688px;
  max-width: 688px;
  @media (max-width: 768px) {
    min-width: 232px;
    max-width: 232px;
  }
`;
const Menu = styled.div`
  border: 1px solid #d5d5d5;
  margin-right: -1px;
  box-sizing: border-box;
  margin-bottom: 52px;
  width: 100%;
  font-weight: 400;
  border-collapse: collapse;
  @media (max-width: 768px) {
    margin-bottom: 23px;
  }
`;
const MunuLabel = styled.div`
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: ${(props) => {
    return props.bold === props.children ? "bold" : "normal";
  }};
  font-size: 18px;
  line-height: 26px;
  text-align: center;
  letter-spacing: -0.05em;
  color: ${(props) => {
    return props.color === props.children ? "#131313" : "rgba(19,19, 19, 0.4)";
  }};
  cursor: pointer;
  @media (max-width: 768px) {
    font-size: 5px;
  }
`;
const Line = styled.div`
  border: 1.5px solid #474747;
  min-width: 1241px;
  @media (max-width: 768px) {
    min-width: 321px;
    max-width: 321px;
    border: 1px solid #474747;
  }
`;
const NoticeDiv = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 8px;
  margin-left: 30px;
  @media (max-width: 768px) {
    margin-top: 0px;
    margin-left: 0px;
    min-width: 321px;
    /* max-width: 321px; */
  }
`;
const NoticeLabel = styled.div`
  font-family: Noto Sans CJK KR;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 26px;
  margin-right: ${(props) => props.RightMargin}px;
  text-align: center;
  letter-spacing: -0.05em;
  color: #454545;
  @media (max-width: 768px) {
    font-size: 6px;
    margin-right: ${(props) => props.MRightMargin}px;
  }
`;

const PagingContainer = styled.div`
  display: flex;
`;
const PagingDiv = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 102px;
  width: 100%;
  @media (max-width: 768px) {
    margin-bottom: 32px;
  }
`;
const PagingButton = styled.img`
  width: 5px;
  height: 8px;
  @media (max-width: 768px) {
    width: 2px;
    height: 5px;
  }
`;
const PagingLabel = styled.div`
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: ${(props) => {
    return props.children === props.bold ? "bold" : "normal";
  }};
  font-size: 12px;
  line-height: 26px;
  /* identical to box height, or 217% */
  text-align: center;
  letter-spacing: -0.05em;
  color: ${(props) => {
    return props.children === props.color
      ? "#656565"
      : "rgba(168, 168, 168, 0.8)";
  }};
  @media (max-width: 768px) {
    font-size: 5px;
    line-height: 15px;
  }
`;
const Paging = styled.div`
  text-align: center;
  border: 1px solid #d5d5d5;
  margin-right: -1px;
  box-sizing: border-box;
  /* transform: rotate(-180deg); */
  width: 28px;
  height: 28px;
  @media (max-width: 768px) {
    width: 18px;
    height: 18px;
    line-height: 9px;
  }
`;
const SearchDiv = styled.div`
  /* margin-top: 7px; */
  margin-left: 1111px;
  margin-top: -131px;
  @media (max-width: 768px) {
    margin-left: 258px;
    margin-top: -56px;
  }
`;
const SearchTop = styled.div`
  display: flex;
  flex-direction: row;
`;
const SearchInput = styled.input.attrs({
  placeholder: "검색어를 입력하세요.",
})`
  margin-left: 5px;
  margin-bottom: 7px;
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 19px;
  letter-spacing: -0.05em;
  border: none;
  color: #9e9e9e;
  @media (max-width: 768px) {
    font-size: 6px;
    line-height: 9px;
    margin-bottom: 5px;
  }
  &:focus {
    outline: none;
  }
`;
const SearchGlass = styled.img`
  margin-left: -10px;
  margin-top: 5px;
  width: 13px;
  height: 14px;
  @media (max-width: 768px) {
    width: 7px;
    height: 7px;
  }
`;
const SearchLine = styled.div`
  width: 150px;
  border: 1px solid #c7c7c7;
  @media (max-width: 768px) {
    /* width: 64px; */
    width: 115px;
    border: 0.5px solid #c7c7c7;
  }
`;

export const NoticeingPage = withRouter(Notice);
