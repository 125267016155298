import React from 'react';
import './App.css';
import Gravision from './Gravision.js';
// import { Provider } from 'react-redux';
// import { store } from './store';

function App() {
  return <Gravision />;
}

export default App;
