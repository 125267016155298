import React, { Component } from "react";
import styled from "styled-components";
// import Carousel from 'react-elastic-carousel';
import Carousel from "nuka-carousel";

class CarouselContainer extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      slideIndex: 0,
      LeftArrow: false,
      RightArrow: false,
      pick: "",
      isMobile: false,
      one: [
        `BIG DATA`,
        `그래비젼만의 기술력을 통해
      신뢰성 높은 빅데이터를 제공하겠습니다.`,
      ],
      two: [
        `AI`,
        `온.오프라인의 방대한 빅데이타를 수집하여 
        크로스 분석을 통한 신뢰도 높은 AI 예측을 제공합니다.`,
      ],
      three: [
        `BLOCKCHAIN`,
        `블록체인은 P2P 방식을 기반으로 
        생성된 체인 형태의 연결고리 입니다. `,
      ],
      items: [
        {
          id: 1,
          bg: require("../../aimg/main_bg.png"),
          title: [
            `BIG DATA`,
            `그래비젼만의 기술력을 통해 신뢰성 높은 빅데이터를 제공하겠습니다.`,
          ],
        },
        {
          id: 2,
          bg: require("../../aimg/carousel1bg.png"),
          title: [
            `BIG DATA`,
            `그래비젼만의 기술력을 통해 신뢰성 높은 빅데이터를 제공하겠습니다.`,
          ],
        },
        {
          id: 3,
          bg: require("../../aimg/carousel2bg.png"),
          title: [
            `AI`,
            `온.오프라인의 방대한 빅데이타를 수집하여 크로스 분석을 통한 신뢰도 높은 AI 예측을 제공합니다.`,
          ],
        },
        {
          id: 4,
          bg: require("../../aimg/carousel3bg.png"),
          title: [
            `BLOCKCHAIN`,
            `블록체인은 P2P 방식을 기반으로 생성된 체인 형태의 연결고리 입니다.`,
          ],
        },
      ],
    };
  }

  render() {
    return (
      <Container>
        {/* {this.carouselZero()}
        {this.carouselOne()}
        {this.carouselTwo()}
        {this.carouselThree()} */}
        {this.carouselTest()}
      </Container>
    );
  }
  carouselTest = () => {
    return (
      <Carousel
        autoplay={true}
        autoplayInterval={3000}
        wrapAround={true}
        slideIndex={this.state.slideIndex}
        defaultControlsConfig={{
          pagingDotsStyle: {
            fill: "#ffffff",
          },
        }}
        renderCenterLeftControls={({ previousSlide }) => (
          <button
            onClick={previousSlide}
            style={{ background: "0", marginLeft: "68px", marginTop: "-7px" }}
            onMouseOver={() => {
              this.setState({
                LeftArrow: true,
              });
            }}
            onMouseOut={() => {
              this.setState({
                LeftArrow: false,
              });
            }}
          >
            {this.state.LeftArrow === true ? (
              <ArrowLeftImg
                src={require("../../aimg/arrowLeftMofi.png")}
                alt="arrowLeftMofi.png"
              />
            ) : (
              <ArrowLeftImg
                src={require("../../aimg/arrowLeft.png")}
                alt="arrowLeft.png"
              />
            )}
          </button>
        )}
        renderCenterRightControls={({ nextSlide }) => (
          <button
            onClick={nextSlide}
            style={{
              background: "0",
              marginRight: "69px",
              marginTop: "-4px",
            }}
            onMouseOver={() => {
              this.setState({
                RightArrow: true,
              });
            }}
            onMouseOut={() => {
              this.setState({
                RightArrow: false,
              });
            }}
          >
            {this.state.RightArrow === true ? (
              <ArrowRightImg
                src={require("../../aimg/arrowRightMofi.png")}
                alt="arrowRightMofi.png"
              />
            ) : (
              <ArrowRightImg
                src={require("../../aimg/arrowRight.png")}
                alt="arrowRight.png"
              />
            )}
          </button>
        )}
      >
        {this.carouselZero()}
        {this.carouselOne()}
        {this.carouselTwo()}
        {this.carouselThree()}
      </Carousel>
    );
  };

  carouselZero = () => {
    return (
      <MainPage
        bg={require("../../aimg/main_bgMofi.png")}
        alt="main_bg.png"
        Mbg={require("../../aimg/Mmain_bg.png")}
      >
        <MainAllDiv>
          <MainContentDiv>
            <MainGroup>
              <GroupImg src={require("../../aimg/Frame.png")} alt="Frame.png" />
            </MainGroup>
            <MainText>
              미래지향 빅데이터 정보처리 분석 기업 <br />
              G-Vision 입니다.
            </MainText>
          </MainContentDiv>
        </MainAllDiv>
      </MainPage>
    );
  };

  carouselOne = () => {
    const [a, b] = this.state.one;
    return (
      <BgContainer
        bg={require("../../aimg/carousel1bg.png")}
        alt="carousel1bg.png"
        Mbg={require("../../aimg/Mcarousel1bg.png")}
      >
        <BgOp op="rgba(0, 0, 0, 0.35)" Mop="rgba(0, 0, 0, 0.25)">
          <BgMargin>
            <Contentdiv>
              <Content
                weight="500"
                size="43"
                lineHeight="64"
                letter="0.03"
                Msize="33"
                MlineHeight="49"
              >
                {a}
              </Content>
              <Content
                weight="300"
                size="30"
                lineHeight="44"
                letter="-0.05"
                top="27"
                Msize="15"
                MlineHeight="22"
                Mletter="-0.1"
              >
                {b.split("\n").map(function (item, idx) {
                  return (
                    <span key={idx}>
                      {item}
                      <br />
                    </span>
                  );
                })}
              </Content>
            </Contentdiv>
            <CarouselImg />
          </BgMargin>
        </BgOp>
      </BgContainer>
    );
  };
  carouselTwo = () => {
    const [a, b] = this.state.two;
    return (
      <BgContainer
        bg={require("../../aimg/carousel2bg.png")}
        alt="carousel2bg.png"
        Mbg={require("../../aimg/Mcarousel2bg.png")}
      >
        <BgOp op="rgba(0, 0, 0, 0.35)" Mop="rgba(0, 0, 0, 0.4)">
          <Contentdiv>
            <Content
              weight="500"
              size="43"
              lineHeight="64"
              letter="0.03"
              Msize="33"
              MlineHeight="49"
            >
              {a}
            </Content>
            <Content
              weight="300"
              size="30"
              lineHeight="44"
              letter="-0.05"
              top="27"
              Msize="15"
              MlineHeight="22"
              Mletter="-0.1"
            >
              {b.split("\n").map(function (item, idx) {
                return (
                  <span key={idx}>
                    {item}
                    <br />
                  </span>
                );
              })}
            </Content>
          </Contentdiv>
          <CarouselImg />
        </BgOp>
      </BgContainer>
    );
  };
  carouselThree = () => {
    const [a, b] = this.state.three;
    return (
      <BgContainer
        bg={require("../../aimg/carousel3bg.png")}
        alt="carousel3bg.png"
        Mbg={require("../../aimg/Mcarousel3bg.png")}
      >
        <BgOp op="rgba(0, 0, 0, 0.25)" Mop="rgba(0, 0, 0, 0.6)">
          <Contentdiv>
            <Content
              weight="500"
              size="43"
              lineHeight="64"
              letter="0.03"
              Msize="33"
              MlineHeight="49"
            >
              {a}
            </Content>
            <Content
              weight="300"
              size="30"
              lineHeight="44"
              letter="-0.05"
              top="27"
              Msize="15"
              MlineHeight="22"
              Mletter="-0.1"
            >
              {b.split("\n").map(function (item, idx) {
                return (
                  <span key={idx}>
                    {item}
                    <br />
                  </span>
                );
              })}
            </Content>
          </Contentdiv>
          <CarouselImg />
        </BgOp>
      </BgContainer>
    );
  };
}
//===== ulCarousel =======
const ArrowLeftImg = styled.img`
  @media (max-width: 768px) {
    display: none;
  }
`;

const ArrowRightImg = styled.img`
  height: 28px;
  @media (max-width: 768px) {
    display: none;
  }
`;

//=========mainPage =======
const MainPage = styled.div`
  display: flex;
  width: 100%;
  height: 808px;
  background: url(${(props) => props.bg}) no-repeat;
  background-size: cover;
  background-position: center;
  @media (max-width: 768px) {
    height: 667px;
    background: url(${(props) => props.Mbg}) no-repeat;
    background-size: cover;
    background-position: center;
  }
`;
const MainAllDiv = styled.div`
  display: flex;
  width: 100%;
  height: 800px;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
  @media (max-width: 768px) {
    height: 667px;
    background-color: rgba(0, 0, 0, 0.3);
  }
`;
const MainContentDiv = styled.div`
  max-width: 1440px;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 768px) {
    max-width: 768px;
  }
`;
const MainGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1440px;
  width: 100%;
  @media (max-width: 768px) {
    max-width: 768px;
  }
`;

const GroupImg = styled.img`
  width: 528px;
  height: 96px;
  @media (max-width: 768px) {
    width: 224px;
    height: 45px;
  }
`;

const MainText = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1440px;
  width: 100%;
  margin-top: 14px;
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: 300;
  font-size: 30px;
  line-height: 44px;
  text-align: center;
  letter-spacing: -0.05em;
  color: #ffffff;
  @media (max-width: 768px) {
    font-size: 15px;
    line-height: 22px;
    text-align: center;
  }
`;
const CarouselImg = styled.img``;

// const MainArrow = styled.div`
//   margin-left: 69px;
// `;
//========================
const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const BgContainer = styled.div`
  width: 100%;
  display: flex;
  background: url(${(props) => props.bg}) no-repeat;
  background-size: cover;
  background-position: center;
  height: 808px;
  top: 0px;
  @media (max-width: 768px) {
    height: 667px;
    background: url(${(props) => props.Mbg}) no-repeat;
    background-size: cover;
    background-position: center;
  }
`;
const BgOp = styled.div`
  background: ${(props) => props.op};
  height: 808px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    height: 100%;
    background: ${(props) => props.Mop};
  }
`;
const BgMargin = styled.div`
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: 768px) {
    max-width: 768px;
  }
`;
const Contentdiv = styled.div`
  /* justify-content: center; */
`;
const Content = styled.div`
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: ${(props) => props.weight};
  font-size: ${(props) => props.size}px;
  line-height: ${(props) => props.lineHeight}px;
  letter-spacing: ${(props) => props.letter}em;
  text-align: center;
  color: #ffffff;
  margin-top: ${(props) => props.Mtop}px;
  @media (max-width: 768px) {
    font-size: ${(props) => props.Msize}px;
    line-height: ${(props) => props.MlineHeight}px;
    letter-spacing: ${(props) => props.Mletter}em;
  }
`;

export { CarouselContainer };
